import React from "react"

import Header from "../Header"
import Footer from "../Footer"

import menuTree from "../../config/menuTree"

import * as css from "./Layout.module.scss"

export default ({ children }) => {
  return (
    <>
      <a href="#main-content" className={css.skipLink}>
        Skip to main content
      </a>
      <Header menuTree={menuTree} />
      <main role="main" id="main-content">
        {children}
      </main>
      <Footer />
    </>
  )
}
