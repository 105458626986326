// @ts-nocheck

import * as React from "react";

import Button from "../Button"
import SVGIcon, { SVGIconSize } from "../SVGIcon"

// import { AnalyticsElementProps } from "../../types"
// import { transformAnalyticsProps } from "../../lib/analytics"

import * as css from "./IconButton.module.scss"

import { SVGIconName } from "../SVGIcon"

type Props = {
  iconName: SVGIconName
  iconVariant?: "black" | "white" | "grey"
  size?: SVGIconSize
  children?: React.ReactNode
  as?: string
  href?: string
  className?: string
  ariaLabel?: string
  onClick?: Function
}

const IconButton: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      iconName,
      iconVariant = "black",
      size,
      children,
      className,
      as,
      href,
      ariaLabel = iconName,
      ...restProps
    },
    ref
  ) => {
    const hasChildren = Boolean(children)

    const contained = (
      <>
        <SVGIcon
          name={iconName}
          size={size || (hasChildren ? "sm" : "md")}
          variant={iconVariant}
          className={css.icon}
        />
        {children}
      </>
    );

    if (as) {
      const AsComponent = as;
      return (
        <AsComponent
          className={[css.iconButton, className || ""].join(" ")}
          {...restProps}
          href={href}
          aria-label={hasChildren ? undefined : ariaLabel}
          ref={ref}
        >
          {contained}
        </AsComponent>
      );
    }

    return (
      <Button
        className={[css.iconButton, className || ""].join(" ")}
        {...restProps}
        aria-label={hasChildren ? undefined : ariaLabel}
        ref={ref}
      >
        {contained}
      </Button>
    )
  }
)

export default IconButton
