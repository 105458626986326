const { allLanguageMap } = require("./mapLanguages")

const lunrSupportedLanguages = [
  "ar",
  "da",
  "de",
  "du",
  "en",
  "es",
  "fi",
  "fr",
  "hu",
  "it",
  "ja",
  "jp",
  "nl",
  "no",
  "pt",
  "ro",
  "ru",
  "sv",
  "tr",
  "vi",
]

module.exports.lunrSupportedLanguages = lunrSupportedLanguages

module.exports.searchLanguages = Object.keys(allLanguageMap)
  .filter(languageCode => lunrSupportedLanguages.indexOf(languageCode) >= 0)
  .map(languageCode => {
    return {
      name: languageCode,
      filterNodes: function(node) {
        return (
          typeof node.fields === "object" &&
          Object.keys(node.fields).length > 0 &&
          typeof node.fields.slug === "string" &&
          node.fields.slug.length > 0 &&
          typeof node.fields.relevantLangCode === "string" &&
          node.fields.relevantLangCode === this.languageCode
        )
      }.bind({ languageCode }),
    }
  })
