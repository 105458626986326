export default [
  {
    name: "Translate a phrase",
    path: "/",
    neverContain: true,
  },
  {
    name: "Languages",
    path: "/language/",
    canContain: [/^\/language\/.+/],
  },
  {
    name: "Phrase",
    path: "/phrase/",
    canContain: [/^\/phrase\/.+/],
  },
  {
    name: "Request a phrase",
    path: "/request/",
  },
]
