// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "Layout-module--buttonUnstyled--tfVhD";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "Layout-module--formRow--RyDqj";
export var listUnstyled = "Layout-module--listUnstyled--X6gZm";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "Layout-module--screenreaderOnly--X-sWR";
export var shadow = "Layout-module--shadow--YTxEC";
export var skipLink = "Layout-module--skipLink--9Qbg4";
export var submitButton = "Layout-module--submitButton--vKT4Q";
export var textFieldTitle = "Layout-module--textFieldTitle--5KKNA";
export var textSmoothing = "Layout-module--textSmoothing--Ga1h9";
export var textUppercase = "Layout-module--textUppercase--a7TYn";