// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "SVGIcon-module--buttonUnstyled--DSDr-";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "SVGIcon-module--formRow---8MKX";
export var grey = "SVGIcon-module--grey--BLq8w";
export var listUnstyled = "SVGIcon-module--listUnstyled--S6z2u";
export var md = "SVGIcon-module--md--Tllei";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "SVGIcon-module--screenreaderOnly--LxEIw";
export var shadow = "SVGIcon-module--shadow--nB81O";
export var sm = "SVGIcon-module--sm--9jL2m";
export var submitButton = "SVGIcon-module--submitButton--y+73M";
export var svg = "SVGIcon-module--svg--MOtWk";
export var svgIcon = "SVGIcon-module--svgIcon--p+yVm";
export var textFieldTitle = "SVGIcon-module--textFieldTitle--Unbzj";
export var textSmoothing = "SVGIcon-module--textSmoothing--TuOV9";
export var textUppercase = "SVGIcon-module--textUppercase--oOmy4";
export var white = "SVGIcon-module--white--q06TE";
export var xs = "SVGIcon-module--xs--RCETX";