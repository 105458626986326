// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "PageHeader-module--buttonUnstyled--9MuXv";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "PageHeader-module--formRow--xQquK";
export var listUnstyled = "PageHeader-module--listUnstyled--x6CCQ";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var pageHeader = "PageHeader-module--pageHeader--9QjDk";
export var pageTitle = "PageHeader-module--pageTitle--zQVY2";
export var screenreaderOnly = "PageHeader-module--screenreaderOnly--JWaic";
export var shadow = "PageHeader-module--shadow--bnlYc";
export var submitButton = "PageHeader-module--submitButton--2gTKW";
export var textFieldTitle = "PageHeader-module--textFieldTitle--qiLul";
export var textSmoothing = "PageHeader-module--textSmoothing--HtDxR";
export var textUppercase = "PageHeader-module--textUppercase--XbrOf";