// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "CollapseSection-module--buttonUnstyled--et6AV";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "CollapseSection-module--formRow--4Qx5G";
export var header = "CollapseSection-module--header--odppq";
export var headerButton = "CollapseSection-module--headerButton--kxNcG";
export var headerContent = "CollapseSection-module--headerContent--+jM08";
export var listUnstyled = "CollapseSection-module--listUnstyled--F27rI";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "CollapseSection-module--screenreaderOnly--9R158";
export var shadow = "CollapseSection-module--shadow--90uWh";
export var submitButton = "CollapseSection-module--submitButton--DzUaO";
export var textFieldTitle = "CollapseSection-module--textFieldTitle--RSn2M";
export var textSmoothing = "CollapseSection-module--textSmoothing--0hv89";
export var textUppercase = "CollapseSection-module--textUppercase--ci+c+";