// extracted by mini-css-extract-plugin
export var aboutSection = "Footer-module--aboutSection--PlHHg";
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var bottomLinks = "Footer-module--bottomLinks--ocYUy";
export var button = "Footer-module--button--92gWN";
export var buttonLine = "Footer-module--buttonLine--dmG+N";
export var buttonUnstyled = "Footer-module--buttonUnstyled--EzIzy";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var column = "Footer-module--column--5O1s3";
export var copyrightSection = "Footer-module--copyrightSection---i8AO";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var footer = "Footer-module--footer--LZE0a";
export var footerImage = "Footer-module--footerImage--nOse3";
export var footerImageLink = "Footer-module--footerImageLink--MwUwE";
export var footerWrap = "Footer-module--footerWrap--a6DHC";
export var formRow = "Footer-module--formRow--TSXvt";
export var listUnstyled = "Footer-module--listUnstyled--XDg2d";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var plsSection = "Footer-module--plsSection--1vDfJ";
export var screenreaderOnly = "Footer-module--screenreaderOnly--FRq8c";
export var sectionHeading = "Footer-module--sectionHeading--mUCOg";
export var shadow = "Footer-module--shadow--Wf2oO";
export var slnswLogo = "Footer-module--slnswLogo--erpx+";
export var slnswLogoWrapper = "Footer-module--slnswLogoWrapper--N7A3y";
export var submitButton = "Footer-module--submitButton--YiusF";
export var textFieldTitle = "Footer-module--textFieldTitle--utKZO";
export var textSmoothing = "Footer-module--textSmoothing--Ik6ve";
export var textUppercase = "Footer-module--textUppercase--YA6QZ";