// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "Header-module--buttonUnstyled--pEb7R";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "Header-module--formRow--Bq4n6";
export var header = "Header-module--header--NXzL8";
export var headerLeft = "Header-module--headerLeft--XaZvs";
export var headerRight = "Header-module--headerRight--QQ8j-";
export var listUnstyled = "Header-module--listUnstyled--KTtr2";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var menuArea = "Header-module--menuArea--kfIWG";
export var menuAreaOpen = "Header-module--menuAreaOpen--D8PDt";
export var menuAreaWithScrollbar = "Header-module--menuAreaWithScrollbar--IUBht";
export var menuButton = "Header-module--menuButton--+XqL2";
export var menuContainer = "Header-module--menuContainer--Rfu1D";
export var menuTreeContainer = "Header-module--menuTreeContainer--AUnA9";
export var menuTreeLink = "Header-module--menuTreeLink--Ohr0O";
export var menuTreeLinkActive = "Header-module--menuTreeLinkActive--Y44ps";
export var menuTreeLinkChild = "Header-module--menuTreeLinkChild--VSyl3";
export var menuTreeList = "Header-module--menuTreeList--ThXN2";
export var menuTreeListItem = "Header-module--menuTreeListItem--HoYos";
export var screenreaderOnly = "Header-module--screenreaderOnly--uc383";
export var shadow = "Header-module--shadow--W4ppx";
export var siteNameLink = "Header-module--siteNameLink--fT1Xf";
export var siteNameLinkWrapper = "Header-module--siteNameLinkWrapper--e2A2c";
export var slnswLogo = "Header-module--slnswLogo--S+Jb3";
export var slnswLogoWrapper = "Header-module--slnswLogoWrapper--sZJwN";
export var submitButton = "Header-module--submitButton--faqfz";
export var textFieldTitle = "Header-module--textFieldTitle--IN1nO";
export var textSmoothing = "Header-module--textSmoothing--ATlNS";
export var textUppercase = "Header-module--textUppercase--SG9sz";