// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "SearchInput-module--buttonUnstyled--fIpZy";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var darkHeaderSearchBlock = "SearchInput-module--darkHeaderSearchBlock--pYzOF";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "SearchInput-module--formRow--NC9pR";
export var headerSearchBlock = "SearchInput-module--headerSearchBlock--x7jy9";
export var listUnstyled = "SearchInput-module--listUnstyled--0tleA";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "SearchInput-module--screenreaderOnly--q-qXb";
export var searchInputBlock = "SearchInput-module--searchInputBlock--forM5";
export var shadow = "SearchInput-module--shadow--rGBZl";
export var submitButton = "SearchInput-module--submitButton--KgLV1";
export var textFieldTitle = "SearchInput-module--textFieldTitle--EzH7Y";
export var textSmoothing = "SearchInput-module--textSmoothing--+mbeJ";
export var textUppercase = "SearchInput-module--textUppercase--w37qw";