import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { lunrSupportedLanguages } from "../../../lib/lunrLanguages"

import * as css from "./SearchInput.module.scss"
import * as utilities from "../../styles/utilities.module.scss"

type Props = {
  initialQuery?: string
  initialLang?: string
  variant: "light" | "dark"
  onSubmit?: Function
}

const SearchInput = ({
  initialQuery = "",
  initialLang = "en",
  variant = "light",
  onSubmit,
}) => {
  const [lang, setLang] = React.useState(initialLang);
  const [query, setQuery] = React.useState(initialQuery);

  const onSubmitEvent = (e) => {
    e.preventDefault()
    if (typeof onSubmit === "function") {
      onSubmit(query, lang);
    }
  };

  const formId = React.useRef(
    `SearchInputForm--${Math.round(Math.random() * 999999)}`
  ).current;

  const renderForm = ({ allLanguagesJson }: any) => {
    const languageOptions = allLanguagesJson.edges
      .filter(
        (edge: any) => lunrSupportedLanguages.indexOf(edge.node.fields.isoCode) >= 0
      )
      .map((edge: any, key: string) => (
        <option key={key} value={edge.node.fields.isoCode}>
          {edge.node.fields.name}
        </option>
      ));
    return (
      <div
        className={[
          css.headerSearchBlock,
          variant === "dark" ? css.darkHeaderSearchBlock : "",
        ].join(" ")}
      >
        <form action="/search" method="get" onSubmit={onSubmitEvent}>
          <div className={css.searchInputBlock}>
            {languageOptions && languageOptions.length > 0 && (
              <>
                <label
                  htmlFor={`${formId}__langSelect`}
                  className={utilities.screenreaderOnly}
                >
                  Select language
                </label>
                <select
                  id={`${formId}__langSelect`}
                  defaultValue={initialLang}
                  onChange={e => setLang(e.target.value)}
                >
                  {languageOptions}
                </select>
              </>
            )}

            <label
              htmlFor={`${formId}__query`}
              className={utilities.screenreaderOnly}
            >
              Enter search keywords
            </label>
            <input
              id={`${formId}__query`}
              type="search"
              placeholder="Search languages and phrases"
              defaultValue={initialQuery}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </form>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SearchInputQuery {
          allLanguagesJson {
            edges {
              node {
                fields {
                  isoCode
                  name
                }
              }
            }
          }
        }
      `}
      render={renderForm}
    />
  );
}

export default SearchInput
