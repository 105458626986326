// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var breadcrumb = "Breadcrumb-module--breadcrumb--42r0P";
export var breadcrumbContainer = "Breadcrumb-module--breadcrumbContainer--cjB-4";
export var buttonUnstyled = "Breadcrumb-module--buttonUnstyled--ZccNv";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "Breadcrumb-module--formRow--xMxQf";
export var listUnstyled = "Breadcrumb-module--listUnstyled--tECH1";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "Breadcrumb-module--screenreaderOnly--TetGv";
export var shadow = "Breadcrumb-module--shadow--ddvmr";
export var submitButton = "Breadcrumb-module--submitButton--ODISh";
export var textFieldTitle = "Breadcrumb-module--textFieldTitle--VAePY";
export var textSmoothing = "Breadcrumb-module--textSmoothing--kaMip";
export var textUppercase = "Breadcrumb-module--textUppercase--DVduK";