// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var button = "Button-module--button--sRE36";
export var buttonUnstyled = "Button-module--buttonUnstyled--tGr2s";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var dark = "Button-module--dark--fb77D";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "Button-module--formRow--fA4C9";
export var grey = "Button-module--grey--KI00x";
export var lg = "Button-module--lg--3C9lC";
export var listUnstyled = "Button-module--listUnstyled--p6oBj";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "Button-module--screenreaderOnly--88DjD";
export var shadow = "Button-module--shadow--4AUsA";
export var sm = "Button-module--sm--HNbCC";
export var submitButton = "Button-module--submitButton--fGlK2";
export var textFieldTitle = "Button-module--textFieldTitle--QDQ+J";
export var textSmoothing = "Button-module--textSmoothing--yIAMC";
export var textUppercase = "Button-module--textUppercase--z5xdu";