import React from "react"

import IconButton from "../IconButton"

import { useMediaQuery } from "../../lib/hooks"

import * as css from "./CollapseSection.module.scss"

type Props = {
  headingChildren?: React.ReactNode
  children?: React.ReactNode
  variant: "light" | "dark"
}

const CollapseSection: React.FC<Props> = ({
  headingChildren = "",
  children,
  variant = "light",
  ...restProps
}) => {
  const [open, setOpen] = React.useState(false)
  const mediaQuery = useMediaQuery()

  const setMenu = mediaQuery === "lg" || mediaQuery === "xlg"
  React.useEffect(() => {
    setOpen(setMenu)
  }, [setMenu])

  return (
    <section {...restProps}>
      <div className={css.header}>
        <div className={css.headerContent}>{headingChildren}</div>
        {!setMenu && (
          <IconButton
            title="Toggle section"
            iconName={open ? "minimise" : "maximise"}
            iconVariant={variant === "light" ? "black" : "white"}
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            className={css.headerButton}
          />
        )}
      </div>
      {open && children}
    </section>
  )
}

export default CollapseSection
