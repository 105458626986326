// @ts-nocheck

import * as React from "react"

import * as css from "./Button.module.scss"

/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['sm', 'dark'] }] */

export interface ButtonProps {
  size?: "sm" | "md" | "lg"
  variant?: "light" | "dark" | "grey"
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  className?: string
  children: string | React.ReactNode
  onClick?: Function
}

// React.forwardRef added because of:
// Warning: Function components cannot be given refs. Attempts to access
// this ref will fail. Did you mean to use React.forwardRef()? Check the
// render method of `Link`.
// https://github.com/zeit/next.js/issues/7915
const Button: React.FunctionComponent<ButtonProps> = React.forwardRef(
  (
    {
      size = "md",
      variant = "light",
      disabled = false,
      type,
      children,
      className,
      ...restProps
    },
    ref
  ) => {
    const classNames = [
      css.button,
      size !== "md" ? css[size] : "",
      variant !== "light" ? css[variant] : "",
      className || "",
    ];
    return (
      <button
        type={type}
        className={classNames.join(" ")}
        disabled={disabled}
        {...restProps}
        ref={ref}
      >
        {children}
      </button>
    )
  }
)

export default Button
