const { codes } = require(`iso-language-codes`)

const allLangNames = require("./langNames.json")

const hardMap = {
  Dinka: "din",
  Dari: "prs",
  Singhalese: "si",
  Slovenian: "sl",
}

module.exports.mapLanguageName = languageName => {
  const codeCandidates = codes.filter(
    c => c.name === languageName || c.name.indexOf(languageName) >= 0
  )
  if (codeCandidates.length > 0) {
    return codeCandidates[0].iso639_1
  }
  return hardMap[languageName] || null
}

const allLanguageMap = allLangNames.reduce((langMap, langName) => {
  const code = module.exports.mapLanguageName(langName)
  langMap[code] = langName
  return langMap
}, {})

module.exports.allLanguageMap = allLanguageMap

// const unicodeLangNameMap = {
//   hy: "հայերեն",
//   bn: "বাংলা",
//   km: "ភាសាខ្មែរ",
//   din: "Thuɔŋjäŋ",
// }
