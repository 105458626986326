import React from "react"
import { Link } from "gatsby"

import * as css from "./Breadcrumb.module.scss"
import * as utilities from "../../styles/utilities.module.scss"

const Breadcrumb = ({ route }) => {
  return (
    <section
      className={css.breadcrumbContainer}
      aria-label="Breadcrumb navigation"
    >
      <nav role="navigation">
        <p id="breadcrumb-label" className={utilities.screenreaderOnly}>
          You are here:
        </p>
        <ol className={css.breadcrumb} aria-labelledby="breadcrumb-label">
          <>
            <li key="corp-site-hp">
              <a href="https://www.sl.nsw.gov.au/">Home</a>
            </li>
            <li key="corp-site-pls">
              <a href="https://www.sl.nsw.gov.au/public-library-services">
                Public Library Services
              </a>
            </li>
            <li key="corp-site-pls-serv">
              <a href="https://www.sl.nsw.gov.au/public-library-services/services-public-libraries">
                Services
              </a>
            </li>
            <li key="corp-site-pls-serv-multi">
              <a href="https://www.sl.nsw.gov.au/public-library-services/multicultural-services-0">
                Multicultural services
              </a>
            </li>
          </>
          {route.map((item, key) => {
            if (key === route.length - 1) {
              return (
                <li className="active" key={key}>
                  {item.name}
                </li>
              )
            } else {
              return (
                <li key={key}>
                  <Link to={item.href}>{item.name}</Link>
                </li>
              )
            }
          })}
        </ol>
      </nav>
    </section>
  )
}

export default Breadcrumb
